<template lang="pug">
router-view
</template>

<script>
import { getCategories } from '../assets/scripts/API'

export default {
  name: 'CatalogPage',
  async beforeRouteEnter (to, from, next) {
    await getCategories()
    next()
  }
}
</script>
